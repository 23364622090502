import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './CompareUniversities.css'; // Import the CSS file for styling

const CompareUniversities = () => {
  const [universities, setUniversities] = useState([]);

  useEffect(() => {
    axios.get('https://admin.webappbazar.com/api/universities')
      .then(response => setUniversities(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="compare-universities-container">
      <h1>Compare Universities</h1>
      <table className="universities-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Country</th>
            <th>Ranking</th>
            <th>Students</th>
            <th>Founded</th>
            <th>Details</th> {/* Add a column for details */}
          </tr>
        </thead>
        <tbody>
          {universities.map(uni => (
            <tr key={uni.id}>
              <td>{uni.name}</td>
              <td>{uni.country}</td>
              <td>{uni.ranking}</td>
              <td>{uni.students}</td>
              <td>{uni.founded}</td>
              <td>
                <Link to={`/universities/${uni.id}`} className="details-link">View Details</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompareUniversities;
