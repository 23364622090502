import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CompareCities = () => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    axios.get('https://admin.webappbazar.com/api/cities')
      .then(response => setCities(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Compare Cities</h1>
      <ul>
        {cities.map(city => (
          <li key={city.id}>{city.name} - {city.population}</li>
        ))}
      </ul>
    </div>
  );
};

export default CompareCities;
