// src/components/ComparePage.js

import React from 'react';
import { useLocation } from 'react-router-dom';
import './ComparePage.css';

const ComparePage = () => {
  const location = useLocation();
  const { state } = location;
  const selectedCards = state ? state.selectedCards : [];

  return (
    <div className="compare-page">
      <h1>Comparison</h1>
      {selectedCards.length === 0 ? (
        <p>No items selected for comparison.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Brand</th>
              <th>Model</th>
              <th>Memory</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {selectedCards.map(card => (
              <tr key={card.id}>
                <td>{card.brand}</td>
                <td>{card.model}</td>
                <td>{card.memory}</td>
                <td>${card.price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ComparePage;
