import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CompareGraphicsCards.css';

const CompareGraphicsCards = () => {
  const [graphicsCards, setGraphicsCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCards, setSelectedCards] = useState([]);
  const [showComparison, setShowComparison] = useState(false);

  useEffect(() => {
    axios.get('https://admin.webappbazar.com/api/graphics-cards')
      .then(response => {
        console.log('API Response:', response.data); // Log API response
        if (Array.isArray(response.data)) {
          setGraphicsCards(response.data);
          setFilteredCards(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    if (term) {
      setFilteredCards(graphicsCards.filter(card =>
        card.model.toLowerCase().includes(term) ||
        card.brand.toLowerCase().includes(term)
      ));
    } else {
      setFilteredCards(graphicsCards);
    }
  };

  const handleSelectCard = (card) => {
    const alreadySelected = selectedCards.find(selected => selected.id === card.id);
    if (alreadySelected) {
      setSelectedCards(selectedCards.filter(selected => selected.id !== card.id));
    } else {
      if (selectedCards.length < 4) {
        setSelectedCards([...selectedCards, card]);
      } else {
        alert('You can compare up to 4 cards only.');
      }
    }
  };

  const handleCompare = () => {
    if (selectedCards.length > 1) {
      setShowComparison(true);
    } else {
      alert('Please select at least 2 cards to compare.');
    }
  };

  if (showComparison) {
    return (
      <div className="comparison-container">
        <h1>Comparison</h1>
        <div className="comparison-table">
          <table>
            <thead>
              <tr>
                <th>Image</th>
                <th>Brand</th>
                <th>Model</th>
                <th>Memory</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {selectedCards.map(card => (
                <tr key={card.id}>
                  <td><img src={card.image_url} alt={`${card.brand} ${card.model}`} className="card-image" /></td>
                  <td>{card.brand}</td>
                  <td>{card.model}</td>
                  <td>{card.memory}</td>
                  <td>${card.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button onClick={() => setShowComparison(false)}>Back</button>
      </div>
    );
  }

  return (
    <div className="compare-graphics-cards">
      <h1>Compare Graphics Cards</h1>
      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch}
        className="search-bar"
      />
      <table className="graphics-card-table">
        <thead>
          <tr>
            <th>Select</th>
            <th>Image</th>
            <th>Brand</th>
            <th>Model</th>
            <th>Memory</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredCards) && filteredCards.length > 0 ? (
            filteredCards.map(card => (
              <tr key={card.id}>
                <td>
                  <button
                    className={`select-btn ${selectedCards.find(selected => selected.id === card.id) ? 'selected' : ''}`}
                    onClick={() => handleSelectCard(card)}
                  >
                    +
                  </button>
                </td>
                <td><img src={card.image_url} alt={`${card.brand} ${card.model}`} className="card-image" /></td>
                <td>{card.brand}</td>
                <td>{card.model}</td>
                <td>{card.memory}</td>
                <td>${card.price}</td>
              </tr>
            ))
          ) : (
            <tr><td colSpan="6">No graphics cards found.</td></tr>
          )}
        </tbody>
      </table>
      <div className="comparison-link">
        <button className="compare-btn" onClick={handleCompare}>Compare</button>
      </div>
      {selectedCards.length >= 4 && (
        <p className="limit-warning">You have selected the maximum number of cards for comparison.</p>
      )}
    </div>
  );
};

export default CompareGraphicsCards;
