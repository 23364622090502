import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import CompareCities from './components/CompareCities';
import CompareGraphicsCards from './components/CompareGraphicsCards';
import CompareUniversities from './components/CompareUniversities';
import ComparePage from './components/ComparePage'; // Import the comparison page component
import CompareSmartphones from './components/CompareSmartphones';
import SmartphoneDetails from './components/SmartphoneDetails';
import SmartphoneCompare from './components/SmartphoneCompare'; // Import your compare page
import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Smartphones" element={<CompareSmartphones />} />
        <Route path="/smartphonecompare" element={<SmartphoneCompare />} />
        <Route path="/smartphonedetails/:id" element={<SmartphoneDetails />} />
        <Route path="/cities" element={<CompareCities />} />
        <Route path="/graphics-cards" element={<CompareGraphicsCards />} />
        <Route path="/universities" element={<CompareUniversities />} />
        <Route path="/compare" element={<ComparePage />} />
      </Routes>
    </Router>
  );
}

export default App;
