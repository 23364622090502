import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './CompareSmartphones.css'; // Ensure you import the CSS file

const CompareSmartphones = () => {
  const [smartphones, setSmartphones] = useState([]);
  const [selectedPhones, setSelectedPhones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://admin.webappbazar.com/api/smartphones')
      .then(response => {
        console.log('API Response:', response.data);
        if (response.data && Array.isArray(response.data.data)) {
          setSmartphones(response.data.data);
        } else {
          setError('Unexpected API response format');
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Failed to fetch data');
        setLoading(false);
      });
  }, []);

  const handleSelectPhone = (phone) => {
    if (selectedPhones.length < 5 && !selectedPhones.includes(phone)) {
      setSelectedPhones([...selectedPhones, phone]);
    } else if (selectedPhones.includes(phone)) {
      setSelectedPhones(selectedPhones.filter(p => p !== phone));
    }
  };

  const handleCompare = () => {
    if (selectedPhones.length >= 2 && selectedPhones.length <= 5) {
      navigate('/smartphonecompare', { state: { phones: selectedPhones } });
    } else {
      alert('Please select at least 2 phones and up to 5 phones to compare.');
    }
  };

  const handleViewDetails = (phone) => {
    navigate(`/smartphonedetails/${phone.SmartphoneID}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h1>Compare Smartphones</h1>
      <div className="phone-list">
        {smartphones.length === 0 ? (
          <div>No data available</div>
        ) : (
          <div className="phone-cards">
            {smartphones.map((phone) => (
              <div
                key={phone.SmartphoneID}
                className={`phone-card ${selectedPhones.includes(phone) ? 'selected' : ''}`}
              >
                <h2 onClick={() => handleViewDetails(phone)} style={{ cursor: 'pointer', color: '#007bff' }}>
                  {phone.ModelName}
                </h2>
                <img src={`https://admin.webappbazar.com/storage/images/${phone.ImageURL}`} alt={phone.ModelName} style={{ width: '100px' }} />
                <p><strong>Price:</strong> {phone.Currency} {phone.Price}</p>
                <button 
                  className="select-button" 
                  onClick={() => handleSelectPhone(phone)}
                  disabled={selectedPhones.length >= 5 && !selectedPhones.includes(phone)}
                >
                  {selectedPhones.includes(phone) ? 'Remove' : 'Select'}
                </button>
                <hr />
              </div>
            ))}
          </div>
        )}
      </div>
      <button 
        className="compare-button" 
        onClick={handleCompare}
        disabled={selectedPhones.length < 2}
      >
        Compare
      </button>
    </div>
  );
};

export default CompareSmartphones;
