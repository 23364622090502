import React from 'react';
import { useLocation } from 'react-router-dom';
import './SmartphoneCompare.css'; // Import the CSS file

const SmartphoneCompare = () => {
  const location = useLocation();
  const { phones } = location.state || {};

  if (!phones || phones.length < 2) {
    return <div>No phones selected for comparison.</div>;
  }

  return (
    <div>
      <h1>Smartphone Comparison</h1>
      <table className="comparison-table">
        <thead>
          <tr>
            <th>Feature</th>
            {phones.map(phone => (
              <th key={phone.SmartphoneID}>
                <img src={`https://admin.webappbazar.com/storage/images/${phone.ImageURL}`} alt={phone.ModelName} style={{ width: '100px' }} />
                <br />
                {phone.ModelName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Price</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.Currency} {phone.Price}</td>
            ))}
          </tr>
          <tr>
            <td>Release Date</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.ReleaseDate}</td>
            ))}
          </tr>
          <tr>
            <td>Screen Size</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.ScreenSize}</td>
            ))}
          </tr>
          <tr>
            <td>Processor</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.Processor}</td>
            ))}
          </tr>
          <tr>
            <td>Camera Setup</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.RearCameraSetup} (Rear), {phone.FrontCamera} (Front)</td>
            ))}
          </tr>
          <tr>
            <td>Battery Capacity</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.BatteryCapacity} mAh</td>
            ))}
          </tr>
          <tr>
            <td>Average User Rating</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.AverageUserRating} ({phone.NumberOfReviews} reviews)</td>
            ))}
          </tr>
          <tr>
            <td>Special Features</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.SpecialFeatures}</td>
            ))}
          </tr>
          <tr>
            <td>Included Accessories</td>
            {phones.map(phone => (
              <td key={phone.SmartphoneID}>{phone.IncludedAccessories}</td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SmartphoneCompare;
