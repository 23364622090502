import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // Import the CSS file

const Home = () => {
  return (
    <div className="home">
      <header className="hero-section">
        <h1>Welcome to CompareEverything</h1>
        <p>Your ultimate destination for comparing various products and services.</p>
        <div className="hero-buttons">
          <Link to="/smartphones" className="hero-btn">Compare Smartphones</Link>
          <Link to="/cities" className="hero-btn">Compare Cities</Link>
          <Link to="/graphics-cards" className="hero-btn">Compare Graphics Cards</Link>
          <Link to="/universities" className="hero-btn">Compare Universities</Link>
        </div>
      </header>

      <section className="features">
        <div className="feature">
          <h2>Comprehensive Comparisons</h2>
          <p>Get detailed comparisons across a wide range of categories including electronics, locations, and more.</p>
        </div>
        <div className="feature">
          <h2>Real-Time Data</h2>
          <p>Access up-to-date information and make informed decisions with our real-time data updates.</p>
        </div>
        <div className="feature">
          <h2>User-Friendly Interface</h2>
          <p>Enjoy a seamless and intuitive experience with our easy-to-use platform.</p>
        </div>
      </section>
    </div>
  );
};

export default Home;
