import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const SmartphoneDetails = () => {
  const { id } = useParams(); // Extract the smartphone ID from the URL
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`https://admin.webappbazar.com/api/smartphones/${id}`)
      .then(response => {
        setPhone(response.data); // Assuming the API returns the full data of the smartphone
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching smartphone details:', error);
        setError('Failed to fetch smartphone details');
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!phone) {
    return <div>No data found</div>;
  }

  // Rendering detailed data
  return (
    <div>
      <h1>{phone.ModelName}</h1>
      <img 
        src={`https://admin.webappbazar.com/storage/images/${phone.ImageURL}`} 
        alt={phone.ModelName} 
        style={{ width: '200px' }} 
      />
      <p><strong>Price:</strong> {phone.Currency} {phone.Price}</p>
      <p><strong>Release Date:</strong> {phone.ReleaseDate}</p>
      <p><strong>Screen Size:</strong> {phone.ScreenSize} inches</p>
      <p><strong>Display Type:</strong> {phone.DisplayType}</p>
      <p><strong>Resolution:</strong> {phone.Resolution}</p>
      <p><strong>Refresh Rate:</strong> {phone.RefreshRate}Hz</p>
      <p><strong>Processor:</strong> {phone.Processor}</p>
      <p><strong>GPU:</strong> {phone.GPU}</p>
      <p><strong>RAM:</strong> {phone.RAM} GB</p>
      <p><strong>Internal Storage:</strong> {phone.InternalStorage} GB</p>
      <p><strong>Expandable Storage:</strong> {phone.ExpandableStorage}</p>
      <p><strong>Battery Capacity:</strong> {phone.BatteryCapacity} mAh</p>
      <p><strong>Charging Speed:</strong> {phone.ChargingSpeed}W</p>
      <p><strong>Wireless Charging:</strong> {phone.WirelessCharging ? 'Yes' : 'No'}</p>
      <p><strong>Rear Camera Setup:</strong> {phone.RearCameraSetup}</p>
      <p><strong>Front Camera:</strong> {phone.FrontCamera}</p>
      <p><strong>Camera Features:</strong> {phone.CameraFeatures}</p>
      <p><strong>Video Resolution:</strong> {phone.VideoResolution}</p>
      <p><strong>Operating System:</strong> {phone.OperatingSystem}</p>
      <p><strong>Custom UI:</strong> {phone.CustomUI || 'None'}</p>
      <p><strong>SIM Type:</strong> {phone.SIMType}</p>
      <p><strong>Network Support:</strong> {phone.NetworkSupport}</p>
      <p><strong>Wi-Fi Version:</strong> {phone.WiFiVersion}</p>
      <p><strong>Bluetooth Version:</strong> {phone.BluetoothVersion}</p>
      <p><strong>NFC:</strong> {phone.NFC ? 'Yes' : 'No'}</p>
      <p><strong>USB Type:</strong> {phone.USBType}</p>
      <p><strong>Dimensions:</strong> {phone.Dimensions}</p>
      <p><strong>Weight:</strong> {phone.Weight} g</p>
      <p><strong>IP Rating:</strong> {phone.IPRating}</p>
      <p><strong>Average User Rating:</strong> {phone.AverageUserRating} (from {phone.NumberOfReviews} reviews)</p>
      <p><strong>Special Features:</strong> {phone.SpecialFeatures}</p>
      <p><strong>Included Accessories:</strong> {phone.IncludedAccessories}</p>
    </div>
  );
};

export default SmartphoneDetails;
